import { useEffect, useState } from 'react';
import {
  Spin,
  Layout,
  Breadcrumb,
} from 'antd';
import { useLocation } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import TablePortalAdministradoras from '../../components/TablePortalAdministradoras';
import * as S from './styles';
import ComboBox from '../../components/ComboBox/ComboBox';
import { useParams } from 'react-router-dom';
import api from '../../service/api';
import { User } from '../acess';
import { Header } from 'antd/lib/layout/layout';
const { Content } = Layout;

interface LocationState {
  id: string;
}

const Admin: React.FC = () => {
  const [isSpinning] = useState<boolean>(false)
  const antIcon = <LoadingOutlined style={{ fontSize: 34, color: "#2ce414" }} spin />
  const location = useLocation<LocationState>(); // Define o tipo do estado
  const id = location.state?.id; // Agora TypeScript sabe que id pode existir
  const [currentUser, setCurrentUser] = useState<User>();
  useEffect(() => {
    async function GetCliente() {
      await api.get(`api/Cliente/${id}`).then(function (response) {
        setCurrentUser(response.data);
        localStorage.setItem('cliente', JSON.stringify(response.data));
      })
        .catch(function (error) {
        });
    };
    GetCliente();
  }, [id]);

  return (
    <Spin indicator={antIcon} spinning={isSpinning}>
      <Header
        style={{
          backgroundColor: "#f0f2f5",
          display: 'flex',
          padding: "0 150px",
          height: "auto",
          maxHeight: "60px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <S.ContainerImage>
          <img src={"data:image/png;base64," + currentUser?.img} alt="logo" />
        </S.ContainerImage>
        <S.ContainerWord>
          Portal Administradora
        </S.ContainerWord>

        <S.MenuContainer>
          <ComboBox currentUser={currentUser} /> {/* Sem alteração no ComboBox */}
        </S.MenuContainer>
      </Header>

      <Layout className="layout">
        <Content style={{ backgroundColor: "white", padding: '0 50px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>

          <TablePortalAdministradoras currentUser={currentUser} />
        </Content>
      </Layout>
    </Spin>
  )
}

export default Admin;