import * as S from './styles';
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';

interface ComboBox {
  currentUser: any
}

const ComboBox: React.FC<ComboBox> = ({ currentUser }) => {
  const history = useHistory();
  const [selectedOption] = useState("more_accesses");
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === 'option1') {
      window.open(currentUser.conciliador, '_blank', 'noopener,noreferrer');
    }
    if (event.target.value === 'option2') {
      window.open(`https://extratos.assessoriacartoes.com.br/redirect/${currentUser.id}`, "_blank");
      // window.open(`http://localhost:3002/redirect/${currentUser.id}`, "_blank");
    }
  };

  return (
    <S.MenuContainer>
      <select
        value={selectedOption}
        onChange={handleSelectChange}
      >
        <option value="more_accesses" disabled hidden>
          MAIS ACESSOS
        </option>
        <option value="option1">CONCILIADOR</option>
        <option value="option2">EXTRATOS BANCÁRIOS</option>
      </select>
    </S.MenuContainer>
  );
};


export default ComboBox;